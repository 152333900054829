import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'

interface IProps {
  value: string
  onChange: (search: string) => void
}

export const EventSearchBar = ({ value, onChange: handleChange }: IProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const styles: React.CSSProperties = {
    background: 'white',
    position: 'relative',
    top: '-3.5rem',
  }
  return (
    <div className="search-bar w-100 mx-auto rounded p-3 border shadow" style={styles}>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <div className="input-group">
              <input
                ref={searchInputRef}
                type="text"
                className="search-input form-control form-control-lg ml-md-3 mr-md-5"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="search"
                value={value}
                onChange={(e) => {
                  handleChange(e.target.value)
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-primary rounded"
                  type="button"
                  id="search"
                  onClick={() => {
                    handleChange(searchInputRef.current?.value || '')
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                  <span className="d-none d-md-inline ml-2">Search</span>
                </button>
              </div>
              {value && (
                <div className="input-group-append">
                  <button
                    className="btn btn-link mx-md-2"
                    type="button"
                    id="search"
                    onClick={() => {
                      handleChange('')
                    }}
                  >
                    <span className="d-inline d-md-none ml-2">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <span className="d-none d-md-inline ml-2">Clear</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
