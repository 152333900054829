import { ErrorResponse, ExploreEvent, ExploreProgram, Venue } from '@citruscamps/citrus-client'
import { faCalendarTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useCookiePreferences } from '../../hooks/useCookiePreferences'
import { EventFilterProps, useFetchInfiniteEvents } from '../../hooks/useFetchEvents'
import translations from '../../translations/en.json'
import { getInitialFilterProps } from '../../utils/event-filter-utils'
import { CardSkeleton } from '../CardSkeleton/CardSkeleton'
import { EventCard } from '../EventCard/EventCard'
import { EventFilters } from './components/EventFilters'
import { EventSearchBar } from './components/EventSearchBar'
import { ProgramHeader } from './components/ProgramHeader'
import { StoreFrontText } from './components/StoreFrontText'
import { startTransition } from 'react'

type PageViewType = 'list' | 'calendar'

interface IProps {
  program: ExploreProgram
  venues: Venue[]
  hasOnline: boolean
}

export const ExploreEvents = ({ program, venues, hasOnline }: IProps) => {
  const { query } = useRouter()
  const [view, setView] = useCookiePreferences<PageViewType>('event-view', 'list')
  const {
    data: events,
    error,
    fetchProps,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isFetching,
    isLoading,
    search,
    isSearching,
    fetchNextPage,
    setFetchProps,
    setSearch,
  } = useFetchInfiniteEvents({
    fetchProps: {
      sort: 'starts_at',
      order: 'ASC',
      filter: getInitialFilterProps({
        programId: program.id,
        venues,
        hasOnline,
        query,
        view,
      }),
    },
  })
  return (
    <>
      <ProgramHeader program={program} />
      <div className="container">
        <EventSearchBar value={search} onChange={setSearch} />
        {!!program.preferences?.store_front_text && (
          <div className="row">
            <div className="col">
              <div className="form-group" style={{ marginTop: '-2rem' }}>
                <StoreFrontText preferences={program.preferences} />
              </div>
            </div>
          </div>
        )}
        <EventFilters
          values={{
            type: fetchProps?.filter?.type || 'camp',
            place_name: fetchProps?.filter?.place_name,
            age: fetchProps?.filter?.age,
            gender: fetchProps?.filter?.gender,
            is_online_event: fetchProps?.filter?.is_online_event,
            tags: fetchProps?.filter?.tags,
            scheduled: fetchProps?.filter?.scheduled,
          }}
          program={program}
          view={view}
          hasOnline={hasOnline}
          venues={venues}
          handleSetView={(value) => {
            const tempProps = getInitialFilterProps({
              programId: program.id,
              venues,
              hasOnline,
              query,
              view: value,
            })
            setFetchProps({
              ...(fetchProps || {}),
              filter: {
                ...fetchProps?.filter,
                tags: tempProps.tags,
                scheduled: tempProps.scheduled,
              },
            })
            startTransition(() => {
              setView(value)
            })
          }}
          onChange={(props: EventFilterProps) => {
            let {
              type,
              place_name,
              age,
              gender,
              tags,
              is_online_event: online_event,
              scheduled,
            } = props
            setFetchProps({
              ...(fetchProps || {}),
              filter: {
                ...(fetchProps?.filter || {}),
                type: type || 'camp',
                place_name,
                age,
                gender,
                tags,
                is_online_event: online_event,
                scheduled,
              },
            })
          }}
        />
        <EventList
          events={events}
          program={program}
          view={view}
          error={error}
          isLoading={isLoading}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          isSearching={isSearching}
          isError={isError}
          fetchNextPage={fetchNextPage}
        />
      </div>
    </>
  )
}

interface IEventListProps {
  events: ExploreEvent[]
  program: ExploreProgram
  view: PageViewType
  error: ErrorResponse | null
  isLoading: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  hasNextPage: boolean
  isSearching: boolean
  isError: boolean
  fetchNextPage: Function
}

const EventList = (props: IEventListProps) => {
  const {
    events,
    program,
    view,
    error,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isSearching,
    isError,
    fetchNextPage,
  } = props

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading || isSearching,
    hasNextPage,
    onLoadMore: () => fetchNextPage(),
    disabled: !!error || isSearching,
    rootMargin: '0% 0% 20% 0%',
  })

  if (isLoading) {
    return (
      <div className="row mt-2" style={{ minHeight: '24rem' }}>
        {Array.from({ length: 6 })
          .fill(null)
          .map((_, i: number) => ['loading_events', i].join(':'))
          .map((key, i) => (
            <div key={key} className="col-sm-12 col-md-6 col-lg-4 my-2 p-md-4">
              <CardSkeleton />
            </div>
          ))}
      </div>
    )
  }
  if (isError || !events) {
    return (
      <div className="row mt-2" style={{ minHeight: '24rem' }}>
        <div className="col my-5 text-center">
          <div className="mb-3">
            <FontAwesomeIcon icon={faCalendarTimes} size="3x" />
          </div>
          <h3>{(translations as any)['error.default_toast_header']}</h3>
          <p>{(translations as any)['error.default_toast_message']}</p>
        </div>
      </div>
    )
  }
  if (events.length === 0) {
    return (
      <div className="row mt-2" style={{ minHeight: '24rem' }}>
        <div className="col my-5 text-center">
          <div className="mb-3">
            <FontAwesomeIcon icon={faCalendarTimes} size="3x" />
          </div>
          <h3>Events not found</h3>
          <p>
            We could not find any upcoming events, try clearing the filters or come back later for
            more.
          </p>
        </div>
      </div>
    )
  }
  return (
    <>
      <div id="event-list" className="row mt-2" style={{ minHeight: '24rem' }}>
        {events.map((event) => (
          <div key={event.id} className="col-sm-12 col-md-6 col-lg-4 my-2 p-md-4">
            <EventCard
              event={event}
              program={program}
              trafficSource={
                view === 'list'
                  ? {
                      utm_source: 'citrus',
                      utm_medium: 'internal',
                      utm_content: 'explore+event+list',
                      utm_term: 'event+card',
                    }
                  : view === 'calendar'
                  ? {
                      utm_source: 'citrus',
                      utm_medium: 'internal',
                      utm_content: 'explore+event+calendar',
                      utm_term: 'event+card',
                    }
                  : undefined
              }
            />
          </div>
        ))}
        {(isFetchingNextPage || isFetching) &&
          Array.from({ length: 6 })
            .fill(null)
            .map((_, i: number) => ['fetching_events', i].join(':'))
            .map((key, i) => (
              <div key={key} className="col-sm-12 col-md-6 col-lg-4 my-2 p-md-4">
                <CardSkeleton />
              </div>
            ))}
      </div>
      <div ref={infiniteRef}></div>
    </>
  )
}
