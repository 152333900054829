import { GetServerSideProps, NextPage } from 'next'
import React from 'react'
import { useFetchCustomerProfile } from '../../../components/CustomerProfileView/hooks/useFetchCustomerProfile'
import { ExploreEvents } from '../../../components/ExploreEvents/ExploreEvents'
import { useFetchVenues } from '../../../components/ExploreEvents/hooks/useFetchVenues'
import { HeaderNavigation } from '../../../components/HeaderNavigation/HeaderNavigation'
import { Layout } from '../../../components/Layout/Layout'
import { useProgram } from '../../../hooks/useFetchProgram'
import { getServerEventsProps } from '../../../server/getServerSideEventsProps'
import { CitrusError } from '../../_error'

const ExploreEventsPage: NextPage = () => {
  const { program, isError, error, isLoading: isLoadingProgram } = useProgram()
  const {
    data: venues,
    hasOnline,
    isLoading: isLoadingVenues,
  } = useFetchVenues({
    programId: program?.id,
    type: 'event',
  })
  const { data: customer, isLoading: isLoadingCustomer } = useFetchCustomerProfile({
    programId: program?.id,
    redirect: false,
  })
  if (isLoadingProgram || isLoadingVenues) {
    return null
  }
  if (isError || !program) {
    return <CitrusError errorCode={error?.code || 500}></CitrusError>
  }
  return (
    <Layout
      title="Upcoming Events"
      keywords={[...(program.tags.length > 0 ? program.tags : program.types), program.name]}
      program={program}
    >
      <HeaderNavigation customerProfile={customer} isLoading={isLoadingCustomer} />
      <ExploreEvents program={program} venues={venues} hasOnline={hasOnline} />
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = getServerEventsProps

export default ExploreEventsPage
